import $ from 'jquery'

// Historical function moved in from sizeTableToWindow() in gradebook.js.
// Resizes the tracker table view to match the window size. Needs to be called
// at least one frame *after* any React geometry has been rendered to the screen.
export function sizeStudentsTableToWindow() {
  if ($(window).width() > 1000) {
    // added to prevent wild scrollbar in IE
    $('body').css('overflow-x', 'hidden')
  }
  const pos = $('#gradebook #data_scroller').parent().position()
  if (pos) {
    let w = window.innerWidth // Find w/h as if scrollbars were not present
    const h = window.innerHeight
    const h_summary = $('#gradebook .objective_scores_row').outerHeight()
    let h_margin = Math.ceil(pos.top + h_summary)
    const w_offset = Number($('#student_tot_scroller').width() ?? 0) * 2
    const w_margin = pos.left + w_offset

    if (w < 1000) {
      w = 1000
    }
    if ($('#gradebook.reportcards').length > 0) {
      h_margin += 100
    }
    if ($('body.fixed').length == 1) {
      $('#data_scroller').css('height', h - h_margin + 'px')
      $('#student_scroller').css('height', h - h_margin + 'px')
      $('#student_tot_scroller').css('height', h - h_margin + 'px')
      $('#student_avg_scroller').css('height', h - h_margin + 'px')
    } else {
      $('#objectives_scroller').css('width', w - w_margin + 'px')
      $('#objectives_scores_scroller').css('width', w - w_margin + 'px')
      $('#data_scroller')
        .css('width', w - w_margin + 'px')
        .css('height', h - h_margin + 'px')
      $('#student_scroller').css('height', h - h_margin + 'px')
      $('#student_tot_scroller').css('height', h - h_margin + 'px')
      $('#student_avg_scroller').css('height', h - h_margin + 'px')
    }
  }
}
