export default function ItemAnalysisBarMap (props) {
  const areas = props.maps_arr;
  const htmlAreas = areas.map((areaData, count) => {
    const builtArea = (
      <area key={"barArea_" + props.counter + "_" + count} data-index={areaData.index} shape="rect" coords={areaData.coords} onClick={() => props.setStudentsBreakdown(props.breakdownId,areaData)} data-kids="" />
    );
    return builtArea;
  });

  return (
    <div className="overlay">
      <img src="/images/item_analysis_overlay.png" useMap={"#imap_" + props.counter} className="new-overlay" />
      <map name={"imap_" + props.counter} id={"imap_" + props.counter} className="bar-breakdown">
        {htmlAreas}
      </map>
    </div>
  );
}
