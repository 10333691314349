import { createRoot } from 'react-dom/client'
import * as React from 'react'
import { camelizeKeys } from '../../../../mc-sdk/case-utils'
import { AssessmentInUseModal } from '../index'

export type ConfigProps = {
  materialId: number
  curriculumMapId?: number
  classroomId?: number
}

let root: ReturnType<typeof createRoot> = null
export const initAssessmentInUseModal = (
  evt?: Event | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  {
    config,
  }: {
    config?: ConfigProps
  } = {}
) => {
  evt?.preventDefault()

  if (root) {
    root.unmount()
  }

  const node = document.createElement('span')
  root = createRoot(node)
  root.render(<AssessmentInUseModal {...camelizeKeys(config)} />)
}
