import { useQuery } from '@tanstack/react-query'
import { PredictiveReportsResource } from '../../../../mc-resources/reports'

export const usePredictiveReportsData = (
  materialId: number,
  classroomId: number,
  predictiveReportsResource: PredictiveReportsResource = PredictiveReportsResource.createInstance(),
  options: { enabled?: boolean } = {}
) => {
  const query = useQuery(
    ['predictiveReports', materialId],
    () => predictiveReportsResource.list({ materialId, classroomId }),
    options
  )

  return {
    ...query,
  }
}
