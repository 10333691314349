import { Billboard, View } from '@instructure/ui'
import { IconSleepingPanda } from 'mc-icons/svg'

const EmptyState = () => {
  return (
    <View as={'div'} width={'35rem'} margin={'x-large auto 0'}>
      <Billboard
        size="medium"
        heading="You Don’t Have Any Reports Yet!"
        hero={<IconSleepingPanda />}
        message="All predictive reports will be stored here. You’ll be able to download and review them after the results are ready."
        headingAs="h2"
        headingLevel="h2"
      />
    </View>
  )
}

export { EmptyState }
