import { AbstractResource } from '../AbstractResource'

class ExportOverviewQueryResource extends AbstractResource {
  _apiBaseUrl = '/administration/exports/reports/overview/districts/'
  _snakeCaseKeys = true

  exportReport = (districtId, schoolId, classObjectiveId, filters) => {
    let path = districtId
    if (schoolId) {
      path += '/schools/' + schoolId
    }
    if (classObjectiveId) {
      path += '/class_objectives/' + classObjectiveId
    }

    this._endpointUrl = path
    return this.create(filters)
  }
}

export { ExportOverviewQueryResource }
