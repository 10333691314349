import ItemAnalysisIndex from '../../../../components/reports/ItemAnalysisIndex'
import { createRoot } from 'react-dom/client'

const TABS_SELECTOR = '[data-load_tab_body]'
const ITEM_ANALYSIS_TAB_CLASS = 'js-item-analysis-tab'
export const initItemAnalysisTab = (rootId: string): void => {
  const container = document.getElementById(rootId)

  if (!container) {
    throw new Error(
      `Unable to find the container for initItemAnalysisTab with id ${rootId}`
    )
    return
  }

  const props = JSON.parse(container.dataset.props || '{}')
  const root = createRoot(container)
  root.render(<ItemAnalysisIndex {...props} />)

  // Unmount the ItemAnalysisIndex when switching to another tab
  const handleReportsTabClick = (evt) => {
    const target = evt.target
    if (!target?.classList.contains(ITEM_ANALYSIS_TAB_CLASS)) {
      root.unmount()
      document.querySelectorAll(TABS_SELECTOR)?.forEach(function (element) {
        element.removeEventListener('click', handleReportsTabClick)
      })
    }
  }

  document.querySelectorAll(TABS_SELECTOR)?.forEach(function (element) {
    element.addEventListener('click', handleReportsTabClick)
  })
}
