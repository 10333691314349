import { useMutation } from '@tanstack/react-query'
import { MaterialResource } from '../../../../mc-resources/materials'

type Props = {
  materialId: number | string
}

export const useClone = () => {
  return useMutation(({ materialId }: Props) => {
    return MaterialResource.createInstance({ _snakeCaseKeys: true }).clone(
      `${materialId}`,
    )
  })
}
