import { MaterialResource } from '../../../mc-resources/materials'
import { createRoot } from 'react-dom/client'
import { useEffect } from 'react'
import { useAlertsContext, AlertsProvider } from '../../../mc-ui/context/AlertsContext'
import { FloatingAlerts } from '../../../mc-ui/collections//FloatingAlerts'

const CloningErrorAlert = () => {
  const { addAlert, alerts } = useAlertsContext()
  useEffect(() => {
    addAlert({variant: 'error', message: 'Unable to clone. Please try again.' })
  }, [addAlert])

  return <FloatingAlerts alerts={alerts} />
}

export const initLegacyCloneButtons = (selector: string) => {
  document.querySelectorAll(selector).forEach((elem) => {
    elem.addEventListener('click', handleClone)
  })
}

const handleClone = async (e: MouseEvent) => {
  try {
    const newMaterialId = await cloneMaterial(e)
    window.location.href = `/materials/${newMaterialId}/edit`
  } catch (err) {
    console.error(err)
    let domNode = document.getElementById('cloning-error-alert')
    if (!domNode) {
      domNode = document.createElement('div')
      domNode.setAttribute('id', 'cloning-error-alert')
      const root = createRoot(domNode)
      root.render(<AlertsProvider><CloningErrorAlert /></AlertsProvider>)
      document.body.prepend(domNode)
    }
    else {
      createRoot(domNode).render(<AlertsProvider><CloningErrorAlert /></AlertsProvider>)
    }
  }
}

const cloneMaterial = async (e: MouseEvent) => {
  const button = e.currentTarget

  if (!(button instanceof HTMLElement)) {
    throw 'invalid element'
  }

  e.preventDefault()

  const materialId = button.dataset.materialId
  if (!materialId) {
    throw 'no materialId'
  }

  const result = await MaterialResource.createInstance().clone(materialId)
  return result.material.id
}
