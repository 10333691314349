import { useState } from 'react'
import { Modal } from '@instructure/ui-modal'
import { Button } from '@instructure/ui'
import { IconEditLine } from '@instructure/ui-icons'
import { Heading } from '@instructure/ui-heading'
import { CloseButton } from '@instructure/ui-buttons'
import { Text } from '@instructure/ui-text'
import { Flex } from '@instructure/ui-flex'
import { View } from '@instructure/ui-view'
import { selectorAttrs } from '../../../mc-ui/utils/selectorAttrs'

type Props = {
  materialId: number
  curriculumMapId?: number
  classroomId?: number
}

const AssessmentInUseModal = ({
  materialId,
  curriculumMapId = null,
  classroomId = null,
}: Props) => {
  const [open, setOpen] = useState(true)

  const handleCloseClick = () => {
    setOpen(false)
  }

  const handleSubmitClick = () => {
    let add = ''
    if (curriculumMapId) add += '?curriculum_map=' + curriculumMapId
    if (classroomId) add += '?classroom=' + classroomId
    window.location.href = '/materials/' + materialId + '/edit' + add
  }

  return (
    <Modal
      label={'Edit Assessment'}
      onDismiss={handleCloseClick}
      shouldCloseOnDocumentClick={false}
      open={open}
    >
      <Modal.Header>
        <CloseButton
          size={'medium'}
          onClick={handleCloseClick}
          screenReaderLabel="Close"
          placement="end"
          offset="medium"
          {...selectorAttrs('assessment-in-use-modal-close')}
        />
        <Heading>
          <Flex alignItems="center" display="inline-flex">
            <Flex.Item margin="0 small 0 0">
              <IconEditLine size="small" color="secondary" />
            </Flex.Item>
            <Flex.Item>
              <Text size="x-large">Edit Assessment</Text>
            </Flex.Item>
          </Flex>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <View as={'div'}>
          <Text weight="bold">Assessment in Use</Text>
        </View>
        <View as={'div'} margin="small large large 0">
          <Text lineHeight={'default'}>
            Only certain elements of this assessment can be
            <br />
            modified.
          </Text>
        </View>
      </Modal.Body>
      <Modal.Footer>
        <Button
          margin="0 x-small 0 0"
          onClick={handleCloseClick}
          {...selectorAttrs('assessment-in-use-modal-cancel')}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleSubmitClick}
          type="button"
          {...selectorAttrs('assessment-in-use-modal-continue')}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { AssessmentInUseModal }
