import { PredictiveReportsResource } from '../../../mc-resources/reports'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PredictiveReports } from './PredictiveReports'
import { View } from '@instructure/ui'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const PredictiveReportsTab = (props: {
  materialId: number
  materialTitle: string
  classroomId: number
  predictiveReportsResource?: PredictiveReportsResource
  // An element to add a scroll event listener to. If not provided - `window` is used
  mountTarget?: HTMLElement
  reportsReady?: boolean
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <View padding={'0 xx-small 0 x-small'} as={'div'}>
        <PredictiveReports {...props} />
      </View>
    </QueryClientProvider>
  )
}

export { PredictiveReportsTab }
