import { AddToCmapWorkflow } from './AddToCmapWorkflow'
import { useState } from 'react'
import { View } from '@instructure/ui'
import { PrivacyLevel } from '../../../curriculum-map/helpers/constants'
import {
  AddToProvider,
  type AddToProviderProps,
} from '../Providers/AddToProvider'
import { CannotAddAssessmentModal } from '../Modals/CannotAddAssessmentModal'
import { selectorAttrs } from '../../../../mc-ui/utils/selectorAttrs'

type Props = AddToProviderProps['values']['contextValues'] & {
  featureFlags?: AddToProviderProps['values']['featureFlags']
  defaultMapPrivacy?: number
}

export const AddToCmapOption = ({ featureFlags, ...contextValues }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const DEFAULT_PRIVACY_OPTION = PrivacyLevel.PUBLIC
  const { material, defaultMapPrivacy } = contextValues

  const providerValues = {
    contextValues: {
      ...contextValues,
      selectedPrivacy: defaultMapPrivacy || DEFAULT_PRIVACY_OPTION,
      scaleId: material.scaleId,
    },
    featureFlags,
  }

  const { canClone, canDeliver, canRevertToDraft } = contextValues.permissions

  const renderModal = () => {
    if (!canDeliver)
      return (
        <CannotAddAssessmentModal
          canClone={canClone}
          canRevertToDraft={canRevertToDraft}
          isOpen={modalIsOpen}
          materialId={contextValues.material.id}
          onDismiss={() => setModalIsOpen(false)}
          resourceType="curriculum map"
        />
      )

    return (
      <AddToCmapWorkflow
        addToCmapModalOpen={modalIsOpen}
        setAddToCmapModalOpen={setModalIsOpen}
      />
    )
  }

  return (
    <AddToProvider values={providerValues}>
      <View
        as="div"
        minWidth="11.0625rem" // Match to legacy pixel width
        onClick={() => setModalIsOpen(true)}
        cursor="pointer"
        {...selectorAttrs('add-to-cmap-option')}
      >
        <View as="a">Add to CMAP</View>
      </View>
      {renderModal()}
    </AddToProvider>
  )
}
