import { useState, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { Modal } from '@instructure/ui-modal'
import { View } from '@instructure/ui-view'
import { Button } from '@instructure/ui'
import { IconCopyLine } from '@instructure/ui-icons'
import { Heading } from '@instructure/ui-heading'
import { CloseButton } from '@instructure/ui-buttons'
import { Text } from '@instructure/ui-text'
import { Flex } from '@instructure/ui-flex'
import { selectorAttrs } from 'mc-ui/utils/selectorAttrs'
import { FloatingAlerts } from 'mc-ui/collections/FloatingAlerts'
import { useAlerts } from 'mc-ui/utils/hooks/useAlerts'

import { AppProvider } from '../MaterialShellModal/providers/AppProvider'
import { CloneReAlignForm } from './CloneReAlignForm'

import { CloneMaterialResponseDTO } from '../../../mc-resources/materials'
import { useCloneReAlign } from './hooks/mutations'

type Props = {
  materialId: string
}

const AssessmentCloneReAlignModal = ({ materialId }) => {
  return (
    <AppProvider>
      <CloneReAlignModal materialId={materialId} />
    </AppProvider>
  )
}

const MODAL_VERTICAL_MARGIN = '8vh'
const HEADER_HEIGHTS = '5rem'
const FOOTER_HEIGHTS = '3.875rem'

const CloneReAlignModal = ({ materialId }: Props) => {
  const cloneReAlignMaterial = useCloneReAlign()
  const { handleSubmit, formState } = useFormContext()
  const { isValid } = formState
  const [open, setOpen] = useState(true)
  const { alerts, addAlert } = useAlerts()
  const disabled =
    !isValid || cloneReAlignMaterial.isLoading || cloneReAlignMaterial.isSuccess
  const closeRef = useRef(null)

  const alertSuccess = (message) => {
    addAlert({
      variant: 'success',
      renderCloseButtonLabel: 'Close',
      message: message,
      timeout: 10000,
    })
  }

  const alertError = (message) => {
    addAlert({
      variant: 'error',
      renderCloseButtonLabel: 'Close',
      message: message,
      timeout: 10000,
      onDismiss: () => {
        closeRef.current?.focus()
      },
    })
  }

  const handleCloseClick = () => {
    setOpen(false)
  }

  const handleSubmitClick = (formData) => {
    const data = {
      ...formData,
      assessmentType: undefined,
      material_csv: undefined,
      source_type: undefined,
      distbenchmark: formData.assessmentType === 'benchmark',
    }

    cloneReAlignMaterial.mutate(
      { materialId, data },
      {
        onSuccess: (response: CloneMaterialResponseDTO) => {
          alertSuccess('Assessment successfully cloned')
          window.location.href = '/materials/' + response.material.id + '/edit'
        },
        onError: (error) => {
          const response = error['response'].json()
          response.then((data) => {
            alertError(data.error)
          })
        },
      }
    )
  }

  const setCloseButtonRef = (node) => {
    closeRef.current = node
  }

  return (
    <Modal
      label={'Clone Assessment'}
      onDismiss={handleCloseClick}
      shouldCloseOnDocumentClick={false}
      open={open}
    >
      <Modal.Header>
        <CloseButton
          elementRef={setCloseButtonRef}
          size={'medium'}
          onClick={handleCloseClick}
          screenReaderLabel="Close"
          placement="end"
          offset="medium"
          {...selectorAttrs('assessment-clone-realign-modal-close')}
        />
        <Heading>
          <Flex alignItems="center" display="inline-flex">
            <Flex.Item margin="0 small 0 0">
              <IconCopyLine size="small" color="secondary" />
            </Flex.Item>
            <Flex.Item>
              <Text size="x-large">Clone Assessment</Text>
            </Flex.Item>
          </Flex>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <FloatingAlerts alerts={alerts} />
        <View
          as={'div'}
          css={{
            minHeight: `calc(100vh - 2*${MODAL_VERTICAL_MARGIN} - ${HEADER_HEIGHTS} - ${FOOTER_HEIGHTS})`,
          }}
          width={'24.5rem'}
        >
          <CloneReAlignForm materialId={materialId} />
        </View>
      </Modal.Body>
      <Modal.Footer>
        <Button
          margin="0 x-small 0 0"
          onClick={handleCloseClick}
          {...selectorAttrs('assessment-clone-realign-modal-cancel')}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleSubmitClick)}
          disabled={disabled}
          type="button"
          {...selectorAttrs('assessment-clone-realign-modal-revert')}
        >
          Clone & Re-Align
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { AssessmentCloneReAlignModal }
