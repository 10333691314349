import { AbstractResource } from '../AbstractResource'
import { snakeCaseKeys } from "../../mc-sdk/case-utils"

class ItemAnalysisDataQueryResource extends AbstractResource {
  constructor() {
    super()
    this._endpointUrl = '/exports/item_analysis/item_analysis_data'
  }

  buildResourceUri = (params) => {
    const queryParams = snakeCaseKeys(params, true)
    const path = this._buildResourcePath(queryParams['classroom_id'])
    delete queryParams['classroom_id']
    const queryStr = this._buildQueryString(queryParams)
    return `${path}${queryStr}`
  }
}

export { ItemAnalysisDataQueryResource }
