import {
    IconTroubleSolid,
    IconCompleteSolid,
} from '@instructure/ui-icons';
import { View } from '@instructure/ui-view';
import { CloseButton } from '@instructure/ui-buttons';
import './item_analysis.scss';

export default function StudentsBreakdown (props) {
  const ar = props.maps_arr;
  let kids = [];
  let answerIcon = (<IconTroubleSolid />);
  let answerStatus = "answer-status incorrect";
  if (ar['correct'] == true) {
    answerIcon = (<IconCompleteSolid />);
    answerStatus = "answer-status correct";
  }
  if  ('data-kids' in ar) {
    JSON.parse(ar['data-kids']).forEach((kid, kidkey) => {
      kids.push(<div key={ar.rel + "kidkey" + kidkey} className="kid-status"> <br />{kid} </div>);
    });
  }
  const areas = (
    <div key={ar.rel}>
      <div className="answer-heading-group">
        <div className={answerStatus}>{answerIcon}</div>
        <div className="answer-heading">Answer: {ar.rel}</div>
      </div>
      {kids}
      <br />
    </div>
  );

  const student_breakdown = (
    <div className={"breakdown " + (props.showBreakdown[props.breakdownId] ? "visible" : "hidden")}>
      <div className="breakdown-kids">
        {areas}
      </div>
      <div className="close-container">
        <CloseButton
        placement="end"
        offset="none"
        onClick={() => {props.setStudentsBreakdown(props.breakdownId,[])}}
        screenReaderLabel="Close"
        tabIndex={-1}
        data-focusable="2"
      />
      </div>
    </div>
  );

  return (
    <div className="breakdownContainer">
      {student_breakdown}
    </div>
  );
}

