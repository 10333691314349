import { useQuery } from '@tanstack/react-query'
import { MaterialResource } from '../../../../mc-resources/materials'

const materialResource = MaterialResource.createInstance()

export const useMaterialQuery = (materialId) => {
  return useQuery(
    ['getMaterial', materialId],
    () => materialResource.read(materialId),
    {
      staleTime: Infinity,
      cacheTime: 0,
    }
  )
}
