import { useMutation } from '@tanstack/react-query'
import { FileId } from '../../../../mc-ui/collections/FilesTree/types'
import { downloadFileByUrl } from '../../../../mc-sdk/file/download'
import { PredictiveReportsResource } from '../../../../mc-resources/reports'

export const useDownloadReports = (
  predictiveReportsResource: PredictiveReportsResource = PredictiveReportsResource.createInstance()
) => {
  const mutation = useMutation(
    ({
      selectedFiles,
      bundleName,
    }: {
      selectedFiles: FileId[]
      bundleName: string
    }) =>
      predictiveReportsResource.exportReports(selectedFiles, bundleName, false),
    {
      onSuccess: ({ downloadLink }, { bundleName }) => {
        downloadFileByUrl(downloadLink, bundleName)
      },
    }
  )

  return mutation
}
