import { useState } from 'react'
import { AddToResourceModal } from '../Modals/AddToResourceModal'
import { NewModal } from '../../../curriculum-map/components/NewModal'
import { useHasForeignStandards } from '../../../curriculum-map/contexts/CmapTrackerContext'

interface Props {
  addToCmapModalOpen: boolean
  setAddToCmapModalOpen: (isOpen: boolean) => void
}

export const AddToCmapWorkflow = ({
  addToCmapModalOpen,
  setAddToCmapModalOpen,
}: Props) => {
  const [createCmapModalOpen, setCreateCmapModalOpen] = useState(false)
  const hasForeignStandards = useHasForeignStandards()

  const handleAddToCmapModalCreateClick = () => {
    setAddToCmapModalOpen(false)
    setCreateCmapModalOpen(true)
  }

  const handleAddToCmapModalDismiss = () => {
    setAddToCmapModalOpen(false)
  }

  return (
    <>
      <AddToResourceModal
        open={addToCmapModalOpen}
        handleDismiss={handleAddToCmapModalDismiss}
        handleCreateClick={handleAddToCmapModalCreateClick}
        resourceType="Curriculum Map"
      />
      {!hasForeignStandards && (
        <NewModal
          newModalOpen={createCmapModalOpen}
          setNewModalOpen={setCreateCmapModalOpen}
        />
      )}
    </>
  )
}
