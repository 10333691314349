import { useEffect, useState } from 'react'
import { ScreenReaderContent, View } from '@instructure/ui'
import { FormFieldGroup } from '@instructure/ui-form-field'
import { selectorAttrs } from 'mc-ui/utils/selectorAttrs'
import { useFormContext } from 'react-hook-form'
import { useMaterialQuery } from './hooks/queries'
import { useCurrentTeacher } from '../../material/AuthorItem/hooks/useCurrentTeacher'
import { useMaterialPrivacy } from '../hooks/useMaterialPrivacy'
import {
  useShellInitConfigQuery,
  useShellContentConsumersResource,
} from '../MaterialShellModal/hooks/queries'
import { MaterialPrivacyConfig } from '../types'
import { Title } from '../MaterialShellModal/AddForm/Title'
import { AssessmentType } from '../MaterialShellModal/AddForm/AssessmentType'
import { Alignment } from '../MaterialShellModal/AddForm/Alignment'
import { Scale } from '../MaterialShellModal/AddForm/Scale'
import { PrivacySelector } from '../PrivacySelector'
import { DistrictSelector } from '../DistrictSelector'
import { AssessmentType as AssessmentTypeEnum } from '../../../mc-resources/materials/MaterialResource'
import { LoadingSpinner } from '../LoadingSpinner'

const CloneReAlignForm = ({ materialId }) => {
  const [initialized, setInitialized] = useState(false)
  const { data: teacher } = useCurrentTeacher()
  const { data: contentConsumers, isLoading: isLoadingContentConsumers } =
    useShellContentConsumersResource()
  const { data: config, isFetching: isFetchingConfig } =
    useShellInitConfigQuery()
  const { data: cloneMaterial, isFetching: isFetchingMaterial } =
    useMaterialQuery(materialId)
  const material = cloneMaterial?.material

  const privacyConfig: MaterialPrivacyConfig = {
    ...config,
    defaultMaterialPrivacy: config?.defaultMaterialPrivacy,
  }
  const {
    options,
    isFetchingOptions,
    optionsUpdatedAt,
    getDefault,
    resetDefault: resetDefaultPrivacy,
  } = useMaterialPrivacy({
    config: privacyConfig,
    currentTeacher: teacher,
  })
  const { watch, setValue } = useFormContext()

  useEffect(() => {
    if (!initialized && config && material) {
      setInitialized(true)
      const fields = [
        { name: 'title', value: 'Copy ' + material.title },
        {
          name: 'assessmentType',
          value: material.distbenchmark
            ? AssessmentTypeEnum.BENCHMARK
            : AssessmentTypeEnum.FORMATIVE,
        },
        { name: 'sourceType', value: material.sourceType },
        { name: 'scale.id', value: material.scale.id },
        {
          name: 'destinationDistrict.id',
          value: config.defaultContentConsumerId,
        },
        {
          name: 'alignment.subject.id',
          value: material.alignment.subjects[0]?.id,
        },
        {
          name: 'alignment.pathway.id',
          value: material.alignment.pathway?.id,
        },
        {
          name: 'alignment.classObjective.id',
          value: material.alignment.classObjective?.id,
        },
        { name: 'privacyLevel', value: material.privacyLevel },
      ]
      fields.forEach((field) => {
        setValue(field.name, field.value, {
          shouldValidate: true,
        })
      })
    }
  }, [config, material, setValue, initialized])

  if (
    !config ||
    isFetchingConfig ||
    isLoadingContentConsumers ||
    !options?.length ||
    isFetchingMaterial
  )
    return (
      <View as="div">
        <LoadingSpinner></LoadingSpinner>
      </View>
    )

  const assessmentType = watch('assessmentType')
  const showDistrictSelector =
    config.canProvideContent && assessmentType === 'benchmark'

  const handleAssessmentTypeChange = (e, value) => {
    if (value === 'formative') {
      setValue('destinationDistrict.id', null)
    }

    resetDefaultPrivacy()
  }

  return (
    <FormFieldGroup
      description={
        <ScreenReaderContent>Clone & Re-Align Assessment</ScreenReaderContent>
      }
    >
      <Title />
      {config.canAuthorDistBenchmark && <Separator />}
      {config.canAuthorDistBenchmark && (
        <AssessmentType
          onChange={handleAssessmentTypeChange}
          defaultValue={config.defaultAssessmentType}
        />
      )}
      {showDistrictSelector && (
        <DistrictSelector
          districts={contentConsumers}
          emptyOptionId={config.defaultContentConsumerId}
        />
      )}
      <Separator />
      <Alignment
        alignmentSources={config.alignmentSources}
        defaultPathways={config.defaultPathways}
      />
      <Separator />
      <PrivacySelector
        key={optionsUpdatedAt}
        config={privacyConfig}
        options={options}
        isFetchingOptions={isFetchingOptions}
        defaultOption={getDefault()}
        {...selectorAttrs('assessment-clone-realign-modal-privacy-level')}
      />
      {config.scales.length > 1 && (
        <Scale
          scales={config.scales}
          defaultScaleId={config.defaultScaleId}
        />
      )}
    </FormFieldGroup>
  )
}

const Separator = () => {
  return (
    <View
      as={'div'}
      borderWidth={'small 0 0 0'}
      width={'100%'}
      margin={'0 0 medium 0'}
    />
  )
}

export { CloneReAlignForm }
