import { ConfigProps } from '../types'
import { createRoot } from 'react-dom/client'
import LegacyWrapper from './LegacyWrapper'
import * as React from 'react'
import { camelizeKeys } from '../../../../mc-sdk/case-utils'

let root: ReturnType<typeof createRoot> = null
export const initMaterialShellModal = (
  evt?: Event | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  {
    config,
  }: {
    config?: ConfigProps
  } = {}
) => {
  evt?.preventDefault()

  if (root) {
    root.unmount()
  }

  const node = document.createElement('span')

  root = createRoot(node)
  root.render(<LegacyWrapper {...camelizeKeys(config)} />)
}
