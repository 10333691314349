import { useEffect, useState } from 'react'

import { ConfirmModal } from '../index'
import { useHoldOpen } from '../../../utils/hooks/animation'

const ConfirmModalLegacy = ({
  element,
  initialOpen = false,
  authenticityToken,
}) => {
  const [open, setOpen] = useState(initialOpen)
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [shouldRender, allowUnmount] = useHoldOpen(open)

  useEffect(() => {
    const handleTriggerClick = (e) => {
      e.preventDefault()
      setOpen(true)
    }

    element?.addEventListener('click', handleTriggerClick)

    return () => element?.removeEventListener('click', handleTriggerClick)
  }, [element])

  const {
    message,
    actionMethod,
    confirmButtonCaption,
    cancelButtonCaption,
    heading,
  } = element.dataset

  const action = element.getAttribute('href')

  const handleClose = () => {
    setOpen(false)
  }

  const handleFormSubmit = async () => {
    setIsFormSubmitting(true)
    const formData = new FormData()
    formData.append('authenticity_token', authenticityToken)
    formData.append('_method', actionMethod)

    const response = await fetch(action, {
      method: actionMethod === 'GET' ? 'GET' : 'POST',
      body: formData,
    })

    if (response.ok) return location.reload()
    if (response.redirected) {
      window.location.href = response.url
      return
    }

    // else an error has occurred
    console.error(`A ${response.status} error has occurred:`, response)
    setIsFormSubmitting(false)
  }

  return (
    shouldRender && (
      <ConfirmModal
        open={open}
        heading={heading}
        message={message}
        onCancel={handleClose}
        onConfirm={async () => await handleFormSubmit()}
        cancelButtonCaption={cancelButtonCaption}
        confirmButtonCaption={confirmButtonCaption}
        confirmLoading={isFormSubmitting}
        onExited={allowUnmount}
      />
    )
  )
}

export { ConfirmModalLegacy }
