import { useMutation } from '@tanstack/react-query'
import {
  MaterialResource,
  CloneReAlignMaterialDTO,
} from '../../../../mc-resources/materials'

type Props = {
  materialId: string
  data: CloneReAlignMaterialDTO
}

export const useCloneReAlign = () => {
  return useMutation(({ materialId, data }: Props) => {
    return MaterialResource.createInstance({ _snakeCaseKeys: true }).clone(
      materialId,
      data
    )
  })
}
