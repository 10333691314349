import { PredictiveReportsResource } from '../../../mc-resources/reports'
import { usePredictiveReportsData } from './hooks/usePredictiveReportsData'
import { FilesTree } from '../../../mc-ui/collections/FilesTree'
import { Loading } from './Loading'
import { usePredictiveFilesTree } from '../../common/hooks/usePredictiveFilesTree'
import { useState } from 'react'
import { FileId } from '../../../mc-ui/collections/FilesTree/types'
import { DownloadReports } from './DownloadReports'
import { View } from '@instructure/ui'
import { EmptyState } from './EmptyState'

const PredictiveReports = ({
  predictiveReportsResource,
  materialId,
  materialTitle,
  classroomId,
  mountTarget,
  reportsReady = true,
}: {
  materialId: number
  materialTitle: string
  classroomId: number
  predictiveReportsResource?: PredictiveReportsResource
  // An element to add a scroll event listener to. If not provided - `window` is used
  mountTarget?: HTMLElement
  reportsReady?: boolean
}) => {
  const { data, isFetching } = usePredictiveReportsData(
    materialId,
    classroomId,
    predictiveReportsResource,
    { enabled: reportsReady }
  )

  const { filesData } = usePredictiveFilesTree(data)

  const [selectedFiles, setSelectedFiles] = useState<FileId[]>([])

  const handleSelectFile = (selected: FileId[]) => {
    setSelectedFiles(selected)
  }

  const showNoResults = data?.files.length === 0

  return reportsReady && !showNoResults ? (
    <>
      {!isFetching && data && (
        <FilesTree
          filesData={filesData}
          mountTarget={mountTarget}
          onSelect={handleSelectFile}
          renderActions={
            <View as={'div'} height={'1.75rem'}>
              <DownloadReports
                selectedFiles={selectedFiles}
                bundleName={materialTitle}
                predictiveReportsResource={predictiveReportsResource}
              />
            </View>
          }
        />
      )}
      {isFetching && <Loading />}
    </>
  ) : (
    <EmptyState />
  )
}

export { PredictiveReports }
