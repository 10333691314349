import { useMutation } from '@tanstack/react-query'
import { MaterialResource } from '../../../../mc-resources/materials'

type RevertToDraftMaterial = {
  materialId: number | string
}

export const useRevertToDraft = () => {
  return useMutation(({ materialId }: RevertToDraftMaterial) => {
    return MaterialResource.createInstance().revertToDraft(materialId)
  })
}
