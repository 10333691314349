import { Alert } from 'mc-ui/utils/hooks/useAlerts'
import { AlertEventTypes } from './types'

const addAlert = (alert: Alert) => {
  const event = new CustomEvent(AlertEventTypes.AddInstUIFlashMessage, {
    detail: alert,
  })

  window.dispatchEvent(event)
}

const resetAlerts = () => {
  const event = new CustomEvent(AlertEventTypes.ResetInstUIFlashMessages)
  window.dispatchEvent(event)
}

export const InstUIFlash = {
  addAlert,
  resetAlerts,
}
