import { View } from '@instructure/ui-view';
import { Text } from '@instructure/ui-text';

export default function ItemAnalysisQuestionInfoRow (props) {
  return (
    <View as="div" className="qi-row" tabIndex={-1} data-focusable="1">
      <Text as="div" weight="bold">
        {props.label}
      </Text>
      <View as="div" padding="0 0 0 small" className="value">
        {props.value}
      </View>
    </View>
  );
}
