import { createRoot } from 'react-dom/client'
import * as React from 'react'
import { camelizeKeys } from '../../../../mc-sdk/case-utils'
import { AssessmentCloneModal } from '../index'

export type ConfigProps = {
  materialId: number | string
}

let root: ReturnType<typeof createRoot> = null
export const initAssessmentCloneModal = (
  evt?: Event | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  {
    config,
  }: {
    config?: ConfigProps
  } = {},
) => {
  evt?.preventDefault()

  if (root) {
    root.unmount()
  }

  const node = document.createElement('span')
  root = createRoot(node)
  root.render(<AssessmentCloneModal {...camelizeKeys(config)} />)
}
