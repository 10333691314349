import { createRoot } from 'react-dom/client'
import { AddToCmapOption } from '../AddToCmapOption'
import { camelizeKeys } from '../../../../../mc-sdk/case-utils'

export const initAddToCmapOption = () => {
  const createRootElement = (container) => {
    const props = JSON.parse(container.dataset.props || '{}')
    const transformedProps = camelizeKeys(props)

    createRoot(container).render(<AddToCmapOption {...transformedProps} />)
  }

  const containers = Array.from(
    document.getElementsByClassName('add-to-cmap-option-root')
  )
  containers.forEach((container: Element, index) => {
    createRootElement(container)
    containers[index].className = 'processed-add-to-cmap-option-root'
  })
}
