import { View } from '@instructure/ui-view';
import ItemAnalysisQuestionInfoRow from './ItemAnalysisQuestionInfoRow';

export default function ItemAnalysisQuestionInfo (props) {
  return (
    <View as="div" width="100%" maxWidth="100%" background="secondary" padding="x-small small" className="question-info">
      <ItemAnalysisQuestionInfoRow label="Type" value={props.type} />
      <ItemAnalysisQuestionInfoRow label="Points" value={props.points} />
      <ItemAnalysisQuestionInfoRow label="DOK" value={props.dok} />
      <ItemAnalysisQuestionInfoRow label="Item" value={props.item} />
    </View>
  );
}