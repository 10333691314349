import { Fragment } from 'react'
import { createRoot } from 'react-dom/client'
import ReactDOM from 'react-dom'

import { InfoIconTooltip } from '../index'

/**
 * Dataset on element can contain props for <InfoIconTooltip/>
 * Available props:
 tip
 placement
 on
 maxWidth
 *
 * @param selector
 * @param options
 */
export const initInfoIconTooltip = ({
  selector = '.js-mc-info-tooltip',
  ...options
} = {}) => {
  const elements = document.querySelectorAll(selector)
  ;[].forEach.call(elements, (element) => {
    const { tip, placement, on, maxWidth } = element.dataset

    ReactDOM.unmountComponentAtNode(element)
    createRoot(element).render(
      <Fragment>
        <InfoIconTooltip
          tip={tip}
          placement={placement}
          on={on}
          maxWidth={maxWidth}
          {...options}
        />
      </Fragment>
    )
  })
}
