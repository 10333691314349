import { Tooltip } from '@instructure/ui-tooltip'
import { IconButton } from '@instructure/ui-buttons'
import { IconInfoLine } from '@instructure/ui-icons'
import { View } from '@instructure/ui-view'
import PropTypes from 'prop-types'

import { PositionPropTypes } from '@instructure/ui-position'

/**
 * @TODO: pass all props to view (allow sizing of tooltip) Describe this in storybook
 *
 * @param placement
 * @param tip
 * @param size
 * @param on
 * @returns {JSX.Element}
 * @constructor
 */
const InfoIconTooltip = (props) => {
    const {
        placement = 'top',
        tip = "Hello. I'm a tool tip",
        size = 'small',
        maxWidth = '30em',
        on = ['click', 'hover', 'focus']
    } = props

    return <Tooltip
        renderTip={
            ()  => <View as={'div'} maxWidth={maxWidth}>{tip}</View>
        }
        placement={placement}
        on={on}
    >
        <IconButton
            size={size}
            renderIcon={IconInfoLine}
            withBackground={false}
            withBorder={false}
            shape="circle"
            screenReaderLabel="Toggle Tooltip"
        />
    </Tooltip>
}

InfoIconTooltip.propTypes = {
    /**
     * The content to render in the tooltip
     */
    tip: PropTypes.node,
    /**
     * The size of the `IconButton`
     */
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    /**
     * The action that causes the Content to display (`click`, `hover`, `focus`)
     */
    on: PropTypes.oneOfType([
        PropTypes.oneOf(['click', 'hover', 'focus']),
        PropTypes.arrayOf(PropTypes.oneOf(['click', 'hover', 'focus']))
    ]),
    /**
     * Specifies where the Tooltip will be placed in relation to the target element.
     * Ex. placement="bottom" will render the Tooltip below the triggering element
     * (Note: if there is not room, it will position opposite. Ex. "top" will
     * automatically switch to "bottom")
     */
    placement: PositionPropTypes.placement,
    /**
     * Tooltip maxWidth
     */
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

InfoIconTooltip.defaultProps = {

    tip: "Hello. I'm a tool tip",

    size: 'small',

    on: ['click', 'hover', 'focus'],

    placement: 'top',

    maxWidth: '30em',
}

export { InfoIconTooltip }
export default InfoIconTooltip