// The material search/index page keeps track of selected materials using
// sessionStorage. These are utility functions to help work with that selection.

type MaterialIds = string[]
enum GlobalKeys {
  Materials = 'material_ids',
  MvpaMaterials = 'mvpa_material_ids',
  MaxSelectable = 'max_selectable_materials',
}

const getSelectedMaterialIds = (): MaterialIds =>
  readIdsFromStorage(
    GlobalKeys.Materials,

    // Temporarily support the old ID format, in case this change is deployed in
    // the middle of a benchmark-selecting session. This should be deleted right
    // away.
    (ids) => {
      if (ids === 'undefined') {
        return []
      } else if (/^[0-9,]+$/.test(ids)) {
        return ids.split(',').filter((id) => id.length > 0)
      }
    }
  )

const getMvpaIds = (): MaterialIds =>
  readIdsFromStorage(GlobalKeys.MvpaMaterials)

const setMaterialSelections = (
  ids: MaterialIds,
  mvpaIds?: MaterialIds,
  maxSelectable?: number
) => {
  sessionStorage.setItem(GlobalKeys.Materials, normalizedIds(ids))

  if (mvpaIds !== undefined)
    sessionStorage.setItem(GlobalKeys.MvpaMaterials, normalizedIds(mvpaIds))

  if (maxSelectable !== undefined) {
    if (typeof maxSelectable === 'number')
      sessionStorage.setItem(GlobalKeys.MaxSelectable, maxSelectable.toString())
    else sessionStorage.removeItem(GlobalKeys.MaxSelectable)
  }
}

const getMaxSelectable = (): number | null =>
  parseInt(sessionStorage.getItem(GlobalKeys.MaxSelectable)) || null

const allMaterialsChecked = () =>
  getMaxSelectable() === getSelectedMaterialIds().length

type LegacyIdsTransformer = (value: string) => string[] | null

const readIdsFromStorage = (
  key: GlobalKeys,
  legacyTransformer?: LegacyIdsTransformer
): MaterialIds => {
  const ids = sessionStorage.getItem(key)
  if (!ids) return []

  const legacyIds = legacyTransformer?.(ids)
  if (legacyIds) return legacyIds

  return JSON.parse(ids) ?? []
}

// We can specify all we like that the ids be strings. However, calls from
// legacy code are not checked.
const normalizedIds = (ids: string[]) =>
  JSON.stringify(ids.map((id) => `${id}`))

export {
  getSelectedMaterialIds,
  getMvpaIds,
  getMaxSelectable,
  allMaterialsChecked,
  setMaterialSelections,
}
