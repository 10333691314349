import { Component } from 'react';
import './item_analysis.scss';
import { IconButton } from '@instructure/ui-buttons'
import { IconPrinterLine } from '@instructure/ui-icons'
import { IconDownloadLine } from '@instructure/ui-icons'
import ItemAnalysisQuestion from 'components/reports/ItemAnalysisQuestion';
import { ItemAnalysisDataQueryResource } from 'mc-resources/exports'
import { Button } from '@instructure/ui-buttons'
import { selectorAttrs } from "mc-ui/utils/selectorAttrs"

export default class ItemAnalysisIndex extends Component {
  constructor(props) {
    super(props);
    this.itemAnalysisQuestionRef = React.createRef();
    this.assessmentResultsPath = props.data.assessment_results_path
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    document.querySelector("#close_dialog").addEventListener('keydown', this.handleCloseKeyPress, false);
    window.itemAnalysisCharts(this.props.data.chart_info);
    this.hideShowPrint(false);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.querySelector("#close_dialog").removeEventListener('keydown', this.handleCloseKeyPress, false);
    this.hideShowPrint(true);
  }

  // handleClick is here so that we can properly unmount the component
  // and don't create memory leaks by leaving mounted components in the browser
  handleClick = (e) => {
    // if the click is in the modal, and isn't a link, do nothing
    if (this.node.contains(e.target) && !(e.target.tagName == "A")) {
      return;
    } else { // if the click is outside the modal, or is a link, unmount the component
      ReactRailsUJS.unmountComponents('#IAReport');
    }
  }

  handleCloseKeyPress = (event) => {
    if(event.key === 'Tab' && event.shiftKey){
      const container = this.itemAnalysisQuestionRef.current;
      const last = container.lastElementChild;
      if(last){
        last.focus();
        event.preventDefault();
      }
    }
  }

  hideShowPrint = (outsideClick) => {
    let printDivs = document.getElementsByClassName("link_items js-print-reports-wrapper");
    for (let i=0; i<printDivs.length; i++) {
      if (outsideClick) {
          printDivs[i].hidden = false;
      } else {
        if (document.getElementsByClassName("modal_subnav")[0].children[0].classList.contains("selected")) {
          printDivs[i].hidden = true;
        }
      }
    }
  }

  exportsItemAnalysisData = () => {
    const { assessment_id, benchmark_id, classroom_id } = this.props;
    const exportsItemAnalysisDataParams = {
      assessment_id,
      benchmark_id,
      classroom_id
    }
    const exportsItemAnalysisDataQuery = ItemAnalysisDataQueryResource.createInstance()
    document.location.href = exportsItemAnalysisDataQuery.buildResourceUri(exportsItemAnalysisDataParams)
  }

  displayAssessmentResultsButton = () => {
    if (this.assessmentResultsPath) {
      return (
        <Button
          id="ia-assessment-results-button"
          size="medium"
          color="secondary"
          href={this.assessmentResultsPath}
          {...selectorAttrs('material-analytics-dashboard')}
          tabIndex={1004}
        >
          Assessment Dashboard
        </Button>
      )
    }
  }

  itemAnalysisPrint = () => {
    try {
      // Print for Safari Web browser having issues with window.print()
      if(!document.execCommand('print', false, null)) {
        window.print()
      }
    } catch {
      window.print()
    }
  }

  render() {
    return (
      <div className="item-analysis-index clearfix" ref={node => this.node = document.getElementsByClassName('jqmWindow')[0]}>
        {this.displayAssessmentResultsButton()}
        <IconButton id="ia-csv-button" screenReaderLabel="CSV" onClick={() => this.exportsItemAnalysisData()} tabIndex={1004}><IconDownloadLine /></IconButton>
        <IconButton id="ia-print-button" screenReaderLabel="Print" onClick={() => this.itemAnalysisPrint()} tabIndex={1004}><IconPrinterLine /></IconButton>
        <ItemAnalysisQuestion data={this.props.data} elementRef={this.itemAnalysisQuestionRef} />
      </div>
    );
  }
}
