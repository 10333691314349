import { Flex } from '@instructure/ui-flex'
import { DelayedRender } from '../../../mc-ui/elements/DelayedRender'
import { Spinner } from '@instructure/ui-spinner'

const Loading = (): JSX.Element => (
  <DelayedRender delay={200}>
    <Flex as={'div'} height={'20rem'} width={'100%'}>
      <Spinner margin={'auto auto'} renderTitle="Loading" size="large" />
    </Flex>
  </DelayedRender>
)

export { Loading }
