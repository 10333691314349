import { createRoot } from 'react-dom/client'
import { ReactNode, useEffect } from 'react'
import { camelizeKeys } from 'mc-sdk/case-utils'
import { PredictiveReportsTab } from '../index'

const initPredictiveReportsTab = (
  selector = '#predictive_reports_root',
  mountTargetSelector,
  initProps: {
    materialId: number
    materialTitle: string
    classroomId: number
  }
) => {
  const root = document.querySelector(selector) as HTMLElement | null

  if (!root) {
    console.warn(`No ${selector} element on the page!`)
    return
  }
  const dataProps = camelizeKeys(JSON.parse(root.dataset.props || '{}'))

  const mountTarget = mountTargetSelector
    ? document.querySelector(mountTargetSelector) || undefined
    : undefined

  const rootNode = createRoot(root)
  rootNode.render(
    <PredictiveReportsTabLegacyWrapper
      unmountMe={() => {
        rootNode.unmount()
      }}
    >
      <PredictiveReportsTab
        {...initProps}
        {...dataProps}
        mountTarget={mountTarget}
      />
    </PredictiveReportsTabLegacyWrapper>
  )
}

const TABS_SELECTOR = '[data-load_tab_body]'
const PRINT_BUTTON_SELECTOR = '.js-print-reports-wrapper'
const PREDICTIVE_REPORTS_TAB_CLASS = 'js-predictive-reports-tab'

const PredictiveReportsTabLegacyWrapper = ({
  children,
  unmountMe,
}: {
  children?: ReactNode
  unmountMe: () => void
}): JSX.Element => {
  /**
   * Unmount Predictive reports when switching to another tab
   */
  useEffect(() => {
    const handleReportsTabClick = (evt: Event) => {
      const target = evt.target as HTMLElement
      if (!target?.classList.contains(PREDICTIVE_REPORTS_TAB_CLASS)) {
        unmountMe()
      }
    }
    document.querySelectorAll(TABS_SELECTOR).forEach(function (element) {
      element.addEventListener('click', handleReportsTabClick)
    })

    return () => {
      document.querySelectorAll(TABS_SELECTOR).forEach(function (element) {
        element.removeEventListener('click', handleReportsTabClick)
      })
    }
  })

  /**
   * Hide the Print button on mount
   * Show the Print button on unmount
   */
  useEffect(() => {
    const printButton = document.querySelector(
      PRINT_BUTTON_SELECTOR
    ) as HTMLElement | null

    printButton.hidden = true
    return () => {
      printButton.hidden = false
    }
  }, [])

  return <div className={'inst-ui-container'}>{children}</div>
}

export { initPredictiveReportsTab }
