import { AddToTrackerWorkflow } from './AddToTrackerWorkflow'
import { useState } from 'react'
import { View } from '@instructure/ui'
import {
  AddToProvider,
  type AddToProviderProps,
} from '../Providers/AddToProvider'
import { CannotAddAssessmentModal } from '../Modals/CannotAddAssessmentModal'
import { selectorAttrs } from '../../../../mc-ui'

type Props = AddToProviderProps['values']['contextValues'] & {
  featureFlags?: AddToProviderProps['values']['featureFlags']
}

export const AddToTrackerOption = ({
  featureFlags,
  ...contextValues
}: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const providerValues = {
    contextValues: { ...contextValues, showCMapForCollab: false },
    featureFlags,
  }

  const { canClone, canDeliver, canRevertToDraft } = contextValues.permissions

  const renderModal = () => {
    if (!canDeliver)
      return (
        <CannotAddAssessmentModal
          canClone={canClone}
          canRevertToDraft={canRevertToDraft}
          isOpen={modalIsOpen}
          materialId={contextValues.material.id}
          onDismiss={() => setModalIsOpen(false)}
          resourceType="tracker"
        />
      )

    return (
      <AddToTrackerWorkflow
        addToTrackerModalOpen={modalIsOpen}
        setAddToTrackerModalOpen={setModalIsOpen}
      />
    )
  }

  return (
    <AddToProvider values={providerValues}>
      <View
        as="div"
        minWidth="11.0625rem" // Match to legacy pixel width
        onClick={() => setModalIsOpen(true)}
        cursor="pointer"
        {...selectorAttrs('add-to-tracker-option')}
      >
        <View as="a">Add to Tracker</View>
      </View>
      {renderModal()}
    </AddToProvider>
  )
}
