import { createRoot } from 'react-dom/client'
import { AddToTrackerOption } from '../AddToTrackerOption'
import { camelizeKeys } from '../../../../../mc-sdk/case-utils'

export const initAddToTrackerOption = () => {
  const createRootElement = (container: HTMLElement) => {
    const props = JSON.parse(container.dataset.props || '{}')
    const transformedProps = camelizeKeys(props)

    createRoot(container).render(<AddToTrackerOption {...transformedProps} />)
  }

  const containers = Array.from(
    document.getElementsByClassName('add-to-tracker-option-root')
  )
  containers.forEach((container: HTMLElement, index) => {
    createRootElement(container)
    containers[index].className = 'processed-add-to-tracker-option-root'
  })
}
