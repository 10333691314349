import { useState, useRef } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Modal } from '@instructure/ui-modal'
import { Button } from '@instructure/ui'
import { IconEditLine } from '@instructure/ui-icons'
import { Heading } from '@instructure/ui-heading'
import { CloseButton } from '@instructure/ui-buttons'
import { Text } from '@instructure/ui-text'
import { Flex } from '@instructure/ui-flex'
import { selectorAttrs } from '../../../mc-ui/utils/selectorAttrs'
import { FloatingAlerts } from '../../../mc-ui/collections/FloatingAlerts'
import { useAlerts } from 'mc-ui/utils/hooks/useAlerts'

import { RevertToDraftMaterialResponseDTO } from '../../../mc-resources/materials'
import { useRevertToDraft } from './hooks/mutations'

export type AssessmentToDraftModalProps = {
  materialId: number | string
  hasBankItems?: boolean
}

const queryClient = new QueryClient()

const AssessmentToDraftModal = ({
  materialId,
  hasBankItems = false,
}: AssessmentToDraftModalProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <RevertToDraftModal materialId={materialId} hasBankItems={hasBankItems} />
    </QueryClientProvider>
  )
}

const RevertToDraftModal = ({
  materialId,
  hasBankItems = false,
}: AssessmentToDraftModalProps) => {
  const [open, setOpen] = useState(true)
  const { alerts, addAlert } = useAlerts()
  const revertToDraftMaterial = useRevertToDraft()
  const disabled =
    revertToDraftMaterial.isLoading || revertToDraftMaterial.isSuccess
  const closeRef = useRef(null)

  const alertSuccess = (message) => {
    addAlert({
      variant: 'success',
      renderCloseButtonLabel: 'Close',
      message: message,
      timeout: 10000,
    })
  }

  const alertError = (message) => {
    addAlert({
      variant: 'error',
      renderCloseButtonLabel: 'Close',
      message: message,
      timeout: 10000,
      onDismiss: () => {
        closeRef.current?.focus()
      },
    })
  }

  const handleCloseClick = () => {
    setOpen(false)
  }

  const handleSubmitClick = () => {
    revertToDraftMaterial.mutate(
      { materialId },
      {
        onSuccess: (response: RevertToDraftMaterialResponseDTO) => {
          if (response.message) {
            alertSuccess(response.message)
          }
          window.location.href = '/materials/' + materialId + '/edit'
        },
        onError: (error) => {
          const response = error['response'].json()
          response.then((data) => {
            alertError(data.error)
          })
        },
      }
    )
  }

  const setCloseButtonRef = (node) => {
    closeRef.current = node
  }

  return (
    <Modal
      label={'Edit Assessment'}
      onDismiss={handleCloseClick}
      shouldCloseOnDocumentClick={false}
      open={open}
      size="small"
      themeOverride={{ smallMaxWidth: '27.5rem' }}
    >
      <Modal.Header>
        <CloseButton
          elementRef={setCloseButtonRef}
          size={'medium'}
          onClick={handleCloseClick}
          screenReaderLabel="Close"
          placement="end"
          offset="medium"
          {...selectorAttrs('assessment-draft-modal-close')}
        />
        <Heading>
          <Flex alignItems="center" display="inline-flex">
            <Flex.Item margin="0 small 0 0">
              <IconEditLine size="small" color="secondary" />
            </Flex.Item>
            <Flex.Item>
              <Text size="x-large">Edit Assessment</Text>
            </Flex.Item>
          </Flex>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <FloatingAlerts alerts={alerts} />
        <Text lineHeight={'default'}>
          The Assessment will be reverted to draft.{' '}
          {hasBankItems && 'Previously authored items will remain published. '}
          Are you sure you want to continue?
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button
          margin="0 x-small 0 0"
          onClick={handleCloseClick}
          {...selectorAttrs('assessment-draft-modal-cancel')}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleSubmitClick}
          disabled={disabled}
          type="button"
          {...selectorAttrs('assessment-draft-modal-revert')}
        >
          Revert to Draft
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { AssessmentToDraftModal }
