import { createRoot } from 'react-dom/client'
import ReactDOM from 'react-dom'

import { Alert } from '../index'

/**
 * Renders Alert in `selector` node with passed params as properties
 * Accepts all of the Alert properties
 *
 * Check https://instructure.design/#Alert
 *
 * @param selector
 * @param message
 * @param options
 */
const initAlert = ({ selector = '.js-mc-alert', message, ...options }) => {
  const elements = document.querySelectorAll(selector)
  ;[].forEach.call(elements, (element) => {
    const { message: dataMessage = message, ...dataProps } = element.dataset

    ReactDOM.unmountComponentAtNode(element)
    createRoot(element).render(
      <Alert {...options} {...dataProps}>
        {dataMessage}
      </Alert>
    )
  })
}

export { initAlert }
