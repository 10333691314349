import { useState, useRef } from 'react'
import { Modal } from '@instructure/ui-modal'
import { Button } from '@instructure/ui'
import { Heading } from '@instructure/ui-heading'
import { CloseButton } from '@instructure/ui-buttons'
import { Text } from '@instructure/ui-text'
import { Flex } from '@instructure/ui-flex'

import { useClone } from './hooks/mutations'

import { AppProvider } from '../MaterialShellModal/providers/AppProvider'
import { FloatingAlerts } from 'mc-ui/collections/FloatingAlerts'
import { useAlerts } from 'mc-ui/utils/hooks/useAlerts'
import { CloneMaterialResponseDTO } from '../../../mc-resources/materials'
import { selectorAttrs } from 'mc-ui/utils/selectorAttrs'

type Props = {
  materialId: number | string
}

const AssessmentCloneModal = ({ materialId }) => {
  return (
    <AppProvider>
      <CloneModal materialId={materialId} />
    </AppProvider>
  )
}

const CloneModal = ({ materialId }: Props) => {
  const cloneMaterial = useClone()
  const [open, setOpen] = useState(true)
  const { alerts, addAlert } = useAlerts()
  const closeRef = useRef(null)

  const alertSuccess = (message) => {
    addAlert({
      variant: 'success',
      renderCloseButtonLabel: 'Close',
      message: message,
      timeout: 10000,
    })
  }

  const alertError = (message) => {
    addAlert({
      variant: 'error',
      renderCloseButtonLabel: 'Close',
      message: message,
      timeout: 10000,
      onDismiss: () => {
        closeRef.current?.focus()
      },
    })
  }

  const handleCloseClick = () => {
    setOpen(false)
  }

  const handleSubmitClick = () => {
    cloneMaterial.mutate(
      { materialId },
      {
        onSuccess: (response: CloneMaterialResponseDTO) => {
          alertSuccess('Assessment successfully cloned')
          window.location.href = '/materials/' + response.material.id + '/edit'
        },
        onError: ({ error }) => alertError(error),
      },
    )
  }

  const setCloseButtonRef = (node) => {
    closeRef.current = node
  }

  const disableButton =
    cloneMaterial.isLoading ||
    cloneMaterial.isSuccess ||
    cloneMaterial.isLoading ||
    cloneMaterial.isSuccess

  return (
    <Modal
      label={'Clone Assessment'}
      onDismiss={handleCloseClick}
      shouldCloseOnDocumentClick={false}
      open={open}
    >
      <Modal.Header>
        <CloseButton
          elementRef={setCloseButtonRef}
          size={'medium'}
          onClick={handleCloseClick}
          screenReaderLabel="Close"
          placement="end"
          offset="medium"
          {...selectorAttrs('assessment-clone-modal-close')}
        />
        <Heading>
          <Text size="x-large">Clone Assessment</Text>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <FloatingAlerts alerts={alerts} />
        <Flex as={'div'} direction={'column'} gap={'small'} width={'24.5rem'}>
          <Text>
            {' '}
            Cloning an assessment will allow you to create a new assessment
            similar to this one.
          </Text>
          <Text>
            {' '}
            If you merely want to keep track of this assessment for future
            reference, use the bookmark feature.
          </Text>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button
          margin="0 x-small 0 0"
          onClick={handleCloseClick}
          {...selectorAttrs('assessment-clone-realign-modal-cancel')}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleSubmitClick}
          type="button"
          interaction={disableButton ? 'disabled' : 'enabled'}
          {...selectorAttrs('assessment-clone-realign-modal-revert')}
        >
          Clone Assessment
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { AssessmentCloneModal }
