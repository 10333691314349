import { Component } from 'react';
import StudentsBreakdown from './StudentsBreakdown';
import ItemAnalysisBarMap from './ItemAnalysisBarMap';
import ItemAnalysisQuestionInfo from './ItemAnalysisQuestionInfo';
import { Popover } from '@instructure/ui-popover';
import { Link } from '@instructure/ui-link';
import { View } from '@instructure/ui-view';
import { Text } from '@instructure/ui-text';
import { CloseButton } from '@instructure/ui-buttons';
import { Heading } from '@instructure/ui-heading';
import {
  IconTroubleSolid,
  IconCompleteSolid,
} from '@instructure/ui-icons';
import {
  instuiPopClear,
  popDescription,
  popTitle,
} from './ItemAnalysisQuestion.module.scss';
import './item_analysis.scss';
import produce from 'immer';

export default class ItemAnalysisQuestion extends Component {
  constructor (props) {
    super(props);

    this.state = {
      showBreakdown: {},
      popContent: {},
      breakdownStudents: {},
    }
  }

  updatePopStatus = (id) => {
    const updatedData = produce(this.state.popContent, newContent => {
      newContent[id] = !newContent[id];
    });
    this.setState({popContent: updatedData});
  }
  setStudentsBreakdown = (bdId,studentData) => {
    const updatedState = produce(this.state, newState => {
      newState.showBreakdown[bdId] = !newState.showBreakdown[bdId];
      newState.breakdownStudents[bdId] = studentData;

      this.setFocusCurrentBar(newState.showBreakdown[bdId])
    });
    this.setState(updatedState);
  }

  //On StudentsBreakdown hiding set focus on current bar in chart and set atribute data-focus=false on the rest of chart bars,
  //otherwise (show StudentsBreakdown) set bars to data-focus=false
  setFocusCurrentBar = (show) => {
    if(show){
      const elements = document.querySelectorAll("rect[data-focus=true]");
      elements.forEach(element => element.setAttribute("data-focus",'false'));
    }
    else{
      document.querySelector("rect[data-focus=true]")?.focus();
    }
  }

  //Returns the list of focusable elements to the item analysis card
  elementsFocusableCard = (el) => {
    const count = el.getAttribute("data-count");
    const bdId = "breakdown_" + count;
    if(this.state.showBreakdown[bdId]){
      return el.querySelectorAll("[data-focusable='2'] > [tabindex='-1']");
    }
    return el.querySelectorAll("[data-focusable='1']");
  }

  //Returns the next focusable element of the "list" from current element with focus "el"
  nextElementFocusableCard = (list, el) => {
    let index = [...list].indexOf(el) + 1;
    if(index > list.length-1){
      index = 0;
    }
    return list[index];
  }

  //Returns the previous focusable element of the "list" from current element with focus "el"
  prevElementFocusableCard = (list, el) => {
    let index = [...list].indexOf(el) - 1;
    if(index < 0){
      index = list.length-1;
    }
    return list[index];
  }

  //Handle keyboard events for item analisys card
  onKeyPressCard = (event) => {
    let el=event.target;
    //When hit Shift+Enter set back focus to card
    if(event.key === 'Enter' && event.shiftKey){
      el.closest(".ia-container").focus();
      event.stopPropagation();
      return;
    }

    //For internal card elements,
    //When hit ArrowDown or Shift+Tab, focus next element in elements_focusable
    //When hit ArrowUp or Tab, focus previous element in elements_focusable
    if(!el.classList.contains("ia-container")){
      if(event.key === 'ArrowDown' || event.key === 'Tab' && !event.shiftKey){
        let elements_focusable=this.elementsFocusableCard(el.closest(".ia-container"));
        let next_el=this.nextElementFocusableCard(elements_focusable, el);
        if(next_el){
          next_el.focus();
        }
        event.preventDefault();
        return;
      }
      if(event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey)){
        let elements_focusable=this.elementsFocusableCard(el.closest(".ia-container"));
        let prev_el=this.prevElementFocusableCard(elements_focusable, el);
        if(prev_el){
          prev_el.focus();
        }
        event.preventDefault();
        return;
      }
      return;
    }
    //When positioned in last card move focus to close modal dialog
    if(event.key === 'Tab' && !event.shiftKey && el == el.parentNode.lastChild){
      event.preventDefault();
      document.querySelector("#close_dialog").focus();
    }
    //When hit Enter go to Question
    if(event.key === 'Enter'){
      el.querySelector(".question-num").focus();
    }
  }

  render() {
    const assessment_items = this.props.data.assessment_items || [];
    const charts_to_render = assessment_items.map((element, count) => {
      let popId = "popover" + count;
      let chartId = "chart_" + (count + 1);
      let breakdownId = "breakdown_" + count;
      let item_info = this.props.data.ordered_item_info[count];
      let breakdown = "";
      let itemBarOverlay = "";
      const modalArea = document.getElementsByClassName('jqmID1')[0];

      const pop_over = (
        <Popover
          id={popId}
          isShowingContent={this.state.popContent[popId]}
          renderTrigger={<Link isWithinText={false} aria-describedby="tip" data-focusable="1">{item_info.objective_name}</Link>}
          onShowContent={(e) => {this.updatePopStatus(popId)}}
          onHideContent={(e, {documentClick}) => {this.updatePopStatus(popId)}}
          placement="top center"
          on="click"
          screenReaderLabel="Popover Objective Description"
          shouldContainFocus
          shouldReturnFocus
          shouldCloseOnDocumentClick
          constrain={modalArea}
          mountNode={modalArea}
          >
            <div className={instuiPopClear}>
              <View padding="medium" display="inline-block" as="div" maxWidth="317px" dir="ltr">
                <Heading level="h2" as="h2" margin="0 0 x-small" className={popTitle}>{item_info.objective_name}</Heading>
                <CloseButton
                  placement="end"
                  offset="small"
                  onClick={() => {this.updatePopStatus(popId)}}
                  screenReaderLabel="Close"
                />
                <div className={popDescription}>
                  {item_info.objective_description}
                </div>
              </View>
            </div>
        </Popover>
      );

      if (this.props.data.maps_arr[count] && !element.hide_breakdown) {
        if (breakdownId in this.state.breakdownStudents && (Object.keys(this.state.breakdownStudents[breakdownId]).length > 0)) {
          breakdown = (<StudentsBreakdown
            maps_arr={this.state.breakdownStudents[breakdownId]}
            setStudentsBreakdown={this.setStudentsBreakdown}
            showBreakdown={this.state.showBreakdown}
            breakdownId={breakdownId} />);
        }
        itemBarOverlay = (<ItemAnalysisBarMap
          setStudentsBreakdown={this.setStudentsBreakdown}
          breakdownId={breakdownId}
          counter={count}
          maps_arr={this.props.data.maps_arr[count]} />);

      }
      let chart_area = (
        <div className={"ia-container " + this.props.data.tags_arr.join(" ")} key={count} data-count={count} tabIndex={1005} onKeyDown={this.onKeyPressCard}>
          <div className="ia-info-box" >
            <h3 className="question-num" tabIndex={-1} data-focusable="1">Question {(count + 1)}</h3>
            {pop_over}
          </div>
          <div className="chart" id={chartId} ></div>
          <ItemAnalysisQuestionInfo
            type={item_info.type}
            points={item_info.points}
            dok={item_info.dok}
            item={item_info.item}
          />
          {itemBarOverlay}
          {breakdown}
        </div>);
// Now that all the things are created for the chart area, put it in the list of charts

      return chart_area
    });
    let output_charts = [];

    for (let i=0; i<charts_to_render.length; i++) {
      // i has to be tested as a count, not an index so there is always a page break after 9 graphs
      if ((i+1) % 9 == 0) {
        output_charts.push(charts_to_render[i]);
        output_charts.push(<div key={"page_break" + i} style={{pageBreakAfter: 'always'}}></div>);
      } else {
        output_charts.push(charts_to_render[i]);
      }
    }
    return (
      <View as="div" elementRef={(ref) => this.props.elementRef.current = ref}>
        {output_charts}
      </View>
    );
  }
}
