import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'

import {ConfirmModalLegacy} from '../ConfirmModalLegacy'

const DEFAULT_SELECTOR = '.js-mc-confirm-modal'

// TODO to remove global variable and use runtimeChunk webpack setting instead to avoid multiple packs entrypoints
// https://webpack.js.org/guides/code-splitting/#prevent-duplication
const modalsDOMNodeRegistry = (window.modalsDOMNodeRegistry = window.modalsDOMNodeRegistry || new WeakMap())

const initConfirmModalLegacy = ({
  selector = DEFAULT_SELECTOR,
  authenticityToken = global.CSRF_TOKEN,
  ...options
} = {}) => {
  const elements = document.querySelectorAll(selector)
  ;[].forEach.call(elements, (element) => {
    let node = modalsDOMNodeRegistry.get(element)
    if (!node) {
      node = document.createElement('span')
      modalsDOMNodeRegistry.set(element, node)
    }

    ReactDOM.unmountComponentAtNode(node)
    createRoot(node).render(
      <ConfirmModalLegacy
        {...options}
        authenticityToken={authenticityToken}
        element={element}
      />
    )
  })
}

const unmountConfirmModalLegacy = ({ selector = DEFAULT_SELECTOR} = {}) => {
  const elements = document.querySelectorAll(selector);
  [].forEach.call(elements, (element) => {
    const node = modalsDOMNodeRegistry.get(element)
    node && ReactDOM.unmountComponentAtNode(node)
  })
}

const initConfirmModalOnDocumentReady = () => {
  document.addEventListener('DOMContentLoaded', () => {
    initConfirmModalLegacy()
  })
}

export {initConfirmModalLegacy, unmountConfirmModalLegacy, initConfirmModalOnDocumentReady}