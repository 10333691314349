import { createRoot } from 'react-dom/client'
import * as React from 'react'
import { camelizeKeys } from '../../../../mc-sdk/case-utils'
import { AssessmentToDraftModal, AssessmentToDraftModalProps } from '../index'

export type ConfigProps = {
  material_id: string
  has_bank_items: 'true' | 'false'
}

let root: ReturnType<typeof createRoot> = null
export const initAssessmentToDraftModal = (
  evt?: Event | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  {
    config,
  }: {
    config?: ConfigProps
  } = {}
) => {
  evt?.preventDefault()

  if (root) {
    root.unmount()
  }

  const node = document.createElement('span')
  root = createRoot(node)
  root.render(<AssessmentToDraftModal {...formatProps(config)} />)
}

const formatProps = (config: ConfigProps): AssessmentToDraftModalProps => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const props = camelizeKeys(config) as any
  props.hasBankItems = props.hasBankItems === 'true'
  return props as AssessmentToDraftModalProps
}
