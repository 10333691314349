import { MaterialShellModal } from '../index'
import { useState } from 'react'
import { ConfigProps } from '../types'

const LegacyWrapper = (props: ConfigProps) => {
  const [open, setOpen] = useState(true)
  return (
    <>
      <MaterialShellModal
        onDismiss={() => setOpen(false)}
        open={open}
        {...props}
      />
    </>
  )
}

export default LegacyWrapper
