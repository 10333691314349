import { Button, IconDownloadLine, Spinner } from '@instructure/ui'
import { useDownloadReports } from './hooks/useDownloadReports'
import { PredictiveReportsResource } from '../../../mc-resources/reports'
import { FileId } from '../../../mc-ui/collections/FilesTree/types'

const DownloadReports = ({
  selectedFiles,
  predictiveReportsResource,
  bundleName,
}: {
  selectedFiles: FileId[]
  bundleName: string
  predictiveReportsResource?: PredictiveReportsResource
}) => {
  const { mutate: downloadReports, isLoading: downloadRequestIsInProgress } =
    useDownloadReports(predictiveReportsResource)

  const handleDownloadReports = () => {
    downloadReports({ selectedFiles, bundleName: bundleName })
  }

  const disableDownload = !selectedFiles.length
  return (
    <>
      {downloadRequestIsInProgress ? (
        <Spinner renderTitle={'Downloading reports'} size={'x-small'} />
      ) : (
        <Button
          size={'small'}
          renderIcon={<IconDownloadLine />}
          disabled={disableDownload}
          data-qa={'download_report_btn'}
          data-impact={'download_report_btn'}
          onClick={handleDownloadReports}
        >
          Download Selected
        </Button>
      )}
    </>
  )
}

export { DownloadReports }
